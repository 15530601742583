import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, CardBody } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import { slugify } from "../utils/utilityFunctions"
const _ = require("lodash")

const BlogPage = () => {
  const [search, setSearch] = useState("")

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark (sort: {fields: [frontmatter___title], order: ASC}) {
        edges {
          node {
            frontmatter {
              title
              date
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges

  //get all project names
  let blogs = []
  _.each(posts, edge => {
    if (_.get(edge, "node.frontmatter.title")) {
      blogs = blogs.concat(edge.node.frontmatter.title)
    }
  })

  //make them unique
  blogs = _.uniq(blogs)
  // rest is done in gatsby-node.js, look for tags as an example.

  // ADD A search/filter one day here //

  const filteredBlogTitles = blogs.filter(blogTitle =>
    blogTitle.toLowerCase().includes(search.toLocaleLowerCase())
  )
  //console.log(filtered_projects)

  return (
    <Layout pageTitle="Tutorials">
      <SEO title="Tutorials" />
      <Card>
        <CardBody className="marginated">
          <div className="padded">
            
            <div style={{ paddingTop: "20px" }}>
              <div className="justify">
                <input
                  className="search"
                  type="search"
                  placeholder="Search"
                  onChange={e => setSearch(e.target.value)}
                />
              </div>
              {/*<ProjectList />*/}
              <div className="border-before">
                <div className="padded">
                <ul className="projectlinks">
                  {filteredBlogTitles.map(blogTitle => (
                    <li key={blogTitle}>
                      <a href={`/post/${slugify(blogTitle)}`}>
                        <h6>{blogTitle}</h6>
                      </a>
                    </li>
                  ))}
                </ul>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Layout>
  )
}

export default BlogPage
